<template>
  <div class="convention-hall page choose-page">
    <van-nav-bar ref="header" class="nav-bar" title="여성 파트너 선택" />
    <div class="convention-item">
      <van-tabs
        ref="topTabs"
        animated
        sticky
        line-width="100px"
        :swipeable="true"
      >
        <van-tab ref="myElement" title="하늘과 땅의 아름다움">
          <!-- {{numHeight}} -->
          <!-- :style="{height:numHeight+'px'}" -->
          <div class="container" :style="{ height: numHeight + 'px' }">
            <div class="card">
              <p class="card_txt">
                회원님 개인정보 보호를 위하여 담당자 상담후 회원가입이
                가능합니다. 기존 회원님의 추천을 통한 회원가입도 가능합니다.
              </p>
            </div>
            <div class="address">
              <!-- <van-row type="flex" justify="" gutter="65" v-for="(v, key) in list" :key="key">
								<van-col span="" offset="" @click="addgo(v)" style="width: 110px" class="">
									<p class="addname">{{ v.text }}</p>
									<img style="width: 100%; height 50px" :src="v.img" alt="" />
								</van-col>
							</van-row>
 -->
              <div
                class="address_li"
                @click="addgo(item)"
                v-for="(item, key) in list"
                :key="key"
              >
                <p class="addname">{{ item.text }}</p>
                <img :src="item.img" alt="" />
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="역사" ref="bottomTabs">
          <div class="card">
            <p class="card_txt">
              회원님 개인정보 보호를 위하여 담당자 상담후 회원가입이 가능합니다.
              기존 회원님의 추천을 통한 회원가입도 가능합니다.
            </p>
          </div>
          <div class="rig-box">
            <p class="rig-title">
              만남 가능한 여성 파트너는 어떤 분들이 있나요?
            </p>
            <p class="rig-content" style="margin-bottom: 30px">
              모델, 승무원, 대학생, 직장인 등 다양한 여성분과 만남이 가능하며,
              오직 저희 플랫폼을 통해서만 만남이 가능합니다.
            </p>
            <p class="rig-title">서비스 가능 지역</p>
            <p class="rig-content">
              전국 모든 도시에서 만남이 가능하며, 일부 도서지역은 예약을 통하여
              만남이 가능합니다.
            </p>
            <!-- <p class="rig-content">
							본 플랫폼은 파트너를 연결해주는 전국적인 데이트 플랫폼입니다. 각 사용자의 개인 정보 보호를 보장하기 위해 고객은 리셉션에 연락하거나 플랫폼 선배의 실명을 소개해야만
							참여할 수 있습니다.
						</p> -->
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
// import p1 from "../../assets/image/avatar1.webp"
export default {
  data() {
    return {
      windowHeight: 0,
      zonghei: 0,
      numHeight: 0,
      list: [
        {
          text: "서울",
          id: 1,
          img: require("../../assets/image/avatar1.webp"),
        },
        {
          text: "부산",
          id: 2,
          img: require("../../assets/image/avatar2.webp"),
        },
        {
          text: "대구",
          id: 3,
          img: require("../../assets/image/avatar3.webp"),
        },
        {
          text: "인천",
          id: 4,
          img: require("../../assets/image/avatar4.webp"),
        },
        {
          text: "광주",
          id: 5,
          img: require("../../assets/image/avatar5.webp"),
        },
        {
          text: "대전",
          id: 6,
          img: require("../../assets/image/avatar6.webp"),
        },
        {
          text: "울산",
          id: 7,
          img: require("../../assets/image/avatar7.webp"),
        },
        {
          text: "세종특별자치시",
          id: 8,
          img: require("../../assets/image/avatar8.webp"),
        },
        {
          text: "제주도",
          id: 9,
          img: require("../../assets/image/avatar9.webp"),
        },
        {
          text: "경기도",
          id: 10,
          img: require("../../assets/image/avatar10.webp"),
        },
        {
          text: "강원도",
          id: 11,
          img: require("../../assets/image/avatar11.webp"),
        },
        {
          text: "충청북도",
          id: 12,
          img: require("../../assets/image/avatar12.webp"),
        },
        {
          text: "충청남도",
          id: 13,
          img: require("../../assets/image/avatar13.webp"),
        },
        {
          text: "전라북도",
          id: 14,
          img: require("../../assets/image/avatar14.webp"),
        },
        {
          text: "전라남도",
          id: 15,
          img: require("../../assets/image/avatar15.webp"),
        },
        {
          text: "경상북도",
          id: 16,
          img: require("../../assets/image/avatar16.webp"),
        },
        {
          text: "경상남도",
          id: 17,
          img: require("../../assets/image/avatar17.webp"),
        },
      ],
      addlist: [
        {
          0: "베이징",
          1: "상하이",
          2: "광저우",
          3: "심천",
        },
        {
          0: "난징",
          1: "쯔엉사",
          2: "충칭",
          3: "시안",
        },
      ],
    };
  },
  methods: {
    addgo(data) {
      this.$router.push({
        path: "/profile?id=" + data.id,
      });
      // if (!localStorage.getItem("token")) {
      //   this.$router.push({ path: "/Login" });
      // } else {
      //   this.$router.push({
      //     path: "/list?id=" + data.id + "&name=" + data.name,
      //   });
      // }
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.addlist = res.data;
        res["data"][0].forEach((item, index) => {
          this.getxuanfeilist(item.id, index);
        });
      });
    },
    getxuanfeilist(id, index) {
      this.$http({
        method: "get",
        url: "xuanfeilist",
        data: {
          id,
        },
      }).then((res) => {
        this.$set(this.addlist[0][index], "img", res.data);
        console.log(this.addlist);
      });
    },
    getHeight() {
      // 使用 this.$refs 访问DOM元素
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight;
      // console.log(this.windowHeight, "windowHeight")

      // this.$nextTick(() => {

      // });

      setTimeout(() => {
        let Height = this.$refs.header.$el.offsetHeight;
        let tabHeights = this.$refs.topTabs.$el.offsetHeight;
        // const bottomTabs = this.$refs.bottomTabs.$el.offsetHeight;
        this.zonghei = Height + tabHeights + 70;
        console.log(Height, "zonghei");
        console.log(this.$refs.header.$el, "header");
        console.log(this.$refs.topTabs.$el, "tabHeights");
        this.numHeight = this.windowHeight - this.zonghei;
        console.log(this.numHeight, "numHeight");
      }, 500);
    },
  },
  mounted() {
    this.updateWindowHeight();
    // this.getHeight();
    window.addEventListener("resize", this.updateWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  created() {
    // this.getAddress();
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-tab__text {
  color: #c24491;
}

::v-deep .van-tabs__line {
  background-color: #c24491 !important;
}
.nav-bar {
  // background: linear-gradient(90deg, #775fd9, #c24491);
  background: #e879f9;
  height: 100px;
}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.container {
  overflow: auto;
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 1.4rem;
  background: #f2f2f5;
  background: url(../../assets/login-bg.png);
  background-size: cover;
  background-position: 50%;
}

.addname {
  // text-align: center;
  // transform: translateY(30px);

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #fff;
  // font-weight: 700;
}

::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}

::v-deep .van-tabs__line {
  background-color: #7e5678;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

.card {
  // background-color: #775fd9;
  // padding: 0.625rem;
  // width: 95%;
  // color: white;
  // font-size: 1rem;
  // margin: 0.625rem auto;
  // line-height: 1.5rem;
  // border-radius: 0.375rem;

  // font-weight: 700;
  width: calc(100% - 40px);
  height: auto;
  margin: 40px auto;
}

.card_txt {
  color: #fff;
  padding: 10px 20px;
  height: auto;
  font-size: 30px;
  padding: 20px 40px;
  margin: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(232, 121, 249, 0.5);
}

::v-deep .van-row--flex {
  // height: 80px;
  width: 100%;
  line-height: 80px;
}

::v-deep .van-col {
  // height: 80px;
  width: 100%;
}

.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}

.rig-title {
  color: #c24491;
  font-size: 32px;
  font-weight: 700;
}

.rig-content {
  font-size: 32px;
  color: #000;
}

::v-deep .van-col {
  color: #c24491;
}

.address {
  width: calc(100% - 1.5rem);
  height: auto;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.address_li {
  width: 220px;
  height: 220px;
  position: relative;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.26667rem;
  }
}
</style>
