<template>
  <div class="container page">
    <van-nav-bar title="정확한 실명" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="save()">등록</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field
        v-model="name"
        label="이름"
        placeholder="실명을 입력해주세요"
      />
    </van-cell-group>
    <p>
      귀하의 계좌 보안을 보장하기 위해 실제 이름은 은행 카드에 기재된 이름과
      일치해야 합니다.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      userInfo: {},
    };
  },
  methods: {
    back() {
      // return window.history.back();
      this.$router.push({
        path: "./Mine",
      });
    },
    save() {
      if (this.userInfo.name) {
        this.$toast("설치를 반복하지 마십시오");
        return true;
      }
      if (this.name === "" || this.name === null || this.name === undefined) {
        this.$toast.fail("당신의 이름을 입력 해주세요");
        return false;
      }
      this.$http({
        method: "get",
        data: {
          name: this.name,
        },
        url: "user_set_name",
      }).then((res) => {
        if (res.code === 200) {
          this.getUserInfo();
          this.name = this.userInfo.name;

          // setTimeout(() => {
          // 	this.$toast(res.msg);
          // }, 500)

          this.$router.push({
            path: "./SetPayPassword",
          });
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log("userInfo===🚀===>", this.userInfo);
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({
        path: "/Login",
      });
    } else {
      this.getUserInfo();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
</style>
