<template>
	<div class="container page">
		<van-nav-bar title="선택한 프로필" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="새로고침 성공">
				<template #pulling>
					새로고침하려면 아래로 스크롤하세요.
				</template>
				<template #loosing>
					새로고침하려면 아래로 스크롤하세요.
				</template>
				<template #loading>
					로드 중
				</template>

				<van-empty v-if="list.length === 0" description="데이터 사용 가능!" />
				<van-list v-else v-model="loading" loading-text="로드 중" :finished="finished" :immediate-check="false"
				
					finished-text="không còn nữa" @load="onLoad">
					<div class="item_list" v-for="(v,key) in list" :key="key">
						<div class="lottery_info">
							<van-image class="cover" :src="v.ico">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="period-number">{{v.expect}}</span>
							<span class="period-number">{{v.name}}</span>
						</div>
						<div class="recent">
							<div class="kuaisan-ball left">
								<van-image class="res-img"
									:src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' +  v.opencode[0]+ '.png' ">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<van-image class="res-img"
									:src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' ">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<van-image class="res-img"
									:src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' ">
									<template v-slot:loading>
										<van-loading type="spinner" />
									</template>
								</van-image>
								<span
									class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
								<span
									class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "대" : "소"}}</span>
								<span
									class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "짝" : "홀"}}</span>
							</div>
						</div>
						<div class="topInfo">
							<span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
							<span v-else>{{v.status_text}}</span>
							<span>배팅금액:{{ v.money }}</span>
						</div>
						<!--   <div class="topInfo">
            <span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
            <span v-else >{{v.win_text}}</span>
            <span>金额：{{v.profit}}</span>
          </div> -->
						<!-- <div class="topInfo">
            <span>任务类型：</span>
            <span>{{v.type}}</span>
          </div> -->
						<div class="time">
							<span>타임서버:{{ v.create_time }}</span>
						</div>
						<div class="time">
							<span>해결 시간:{{ v.update_time }}</span>
							<span style="float: right">게임 방법:{{ ((v.data || [])[0] || {}).type }}</span>
						</div>
					</div>
				</van-list>
				
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				isLoading: false,
				finished: false,
				list: [],
				page: 1,
				totalCount:0,
				loading: false,
				refreshing: false,
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast('새로고침 성공');
					this.isLoading = false;
				}, 500);
			},
			onLoad() {
				let timer = setTimeout(() => {
					// if (this.refreshing) {
					// 	this.list = [];
					// 	this.refreshing = false;
					// }
					this.page+=1;
					this.loading = false;
					this.List();
					if (this.list.length == this.totalCount) {
						this.finished = true;
						return
					}
					this.finished && clearTimeout(timer); //清除计时器
				}, 500);
			},
			List() {
				this.$http({
					method: 'get',
					url: 'user_get_game_list',
					data: {
						page: this.page
					},
				}).then(res => {
					if (res.code === 200) {
						let listArr = res.sData.data;
						this.totalCount = res.sData.totalCount;
						this.list = this.page > 1 ? this.list.concat(listArr) : listArr;
						console.log(this.list)
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			}
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.List();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}
	::v-deep .van-loading__text {
		color: #000000;
		font-size: 35px;
	}
	.container{
		height: 100vh;
	}
	.container .main {
		position: relative;
		overflow: auto;
		background-color: #f2f2f5;
		height: 100%;
		padding: 0 10px;
	}

	.item_list {
		padding: 15px 15px;
		margin: 30px 10px;
		background: #fff;
		border-radius: 10px;
		line-height: 60px;
	}

	.item_list .topInfo span {
		flex: 1;
		font-size: 35px;
		font-weight: 700;
		color: #ff253f;
	}

	.item_list .time span {
		flex: 1;
		font-size: 25px;
		font-weight: 500;
		color: #000;
	}

	.item_list .topInfo span:last-child {
		float: right;
	}

	.item_list .desc span {
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
	}

	.item_list .cover {
		width: 60px;
		height: 60px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.item_list .period-number {
		margin-left: 50px;
		margin-right: 10px;
		height: 50px;
		line-height: 60px;
		font-size: 35px;
		font-weight: 700;
		color: #000;
	}

	.item_list .lottery_info {
		display: flex;
	}

	.recent {
		display: flex;
		align-items: center;
		height: 100px;
	}

	.kuaisan-ball .left {
		justify-content: flex-start;
	}

	.kuaisan-ball {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .res-img {
		width: 70px;
		height: 70px;
		margin-right: 30px;
	}

	.kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #000;
	}

	.kuaisan-ball .res-des.middle {
		width: 15%;
		font-size: 35px;
	}
</style>