import Vue from 'vue'
import http from '../http';

import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */






Vue.use(VueRouter)
const routes = [
    { path: '/', redirect: '/Home', component: Home, meta: { title: 'SP' } },
    { path: '/Home', name: 'home', component: Home, meta: { title: 'SP' } },
    { path: '/Choose', name: 'choose', component: Choose, meta: { title: 'SP' } },
    { path: '/List', name: 'list', component: List, meta: { title: 'SP' } },
    { path: '/Profile', name: 'profile', component: Profile, meta: { title: 'SP' } },
    { path: '/Mine', name: 'mine', component: Mine, meta: { title: 'SP' } },
    { path: '/Video', name: 'video', component: Video, meta: { title: 'SP' } },
    { path: '/Game', name: 'game', component: Game, meta: { title: 'SP' } },
    { path: '/Login', name: 'login', component: Login, meta: { title: 'SP' } },
    { path: '/Register', name: 'register', component: Register, meta: { title: 'SP' } },
    { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: 'SP' } },
    { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: 'SP' } },
    { path: '/Setting', name: 'Setting', component: Setting, meta: { title: 'SP' } },
    { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: 'SP' } },
    { path: '/Setname', name: 'Setname', component: Setname, meta: { title: 'SP' } },
    { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: 'SP' } },
    { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: 'SP' } },
    { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: 'SP' } },
    { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { title: 'SP' } },
    { path: '/Notice', name: 'Notice', component: Notice, meta: { title: 'SP' } },
    { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: 'SP' } },
    { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: 'SP' } },
    { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: 'SP' } },
    { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: 'SP' } },
    { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: 'SP' } },
    { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: 'SP' } },
    { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: 'SP' } },


];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach(async (to, from, next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    if (to.path === '/Login') {
        next()
    } else {
        try {
            let res = await http({
                method: "get",
                url: "user_info",
            })
            if (res.code === -1 && res.msg === '네트워크가 불안정합니다') {
                localStorage.clear();
                next('/Login')
            } else {
                next()
            }
        } catch (error) {
            next()
        }
    }

})

export default router