<template>
  <div class="convention-hall page">
    <van-nav-bar ref="header" title="여성 파트너 선택" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>

    <div class="convention-item">
      <van-tabs
        v-model="Tabactive"
        ref="topTabs"
        animated
        sticky
        line-width="100px"
        :swipeable="true"
        @change="changeBtn"
      >
        <van-tab title="하늘과 땅의 아름다움" @click="$router.go(-1)">
          <!-- {{numHeight}} -->
          <!-- :style="{height:numHeight+'px'}" -->
          <div class="container" :style="{ height: numHeight + 'px' }">
            <!-- <div class="card">
							<p class="card_txt">회원님 개인정보 보호를 위하여 담당자 상담후 회원가입이 가능합니다. 기존 회원님의 추천을 통한 회원가입도 가능합니다.</p>
						</div> -->
            <div class="address">
              <div class="address_li" v-for="(item, key) in list" :key="key">
                <!-- <p class="addname">{{ v.text }}</p> -->
                <div v-if="item.id == index">
                  <div v-for="(item1, key1) in item.arrImg" :key="key1">
                    <img :src="item1" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="역사" ref="bottomTabs">
          <div class="card">
            <p class="card_txt">
              회원님 개인정보 보호를 위하여 담당자 상담후 회원가입이 가능합니다.
              기존 회원님의 추천을 통한 회원가입도 가능합니다.
            </p>
          </div>
          <div class="rig-box">
            <p class="rig-title">
              만남 가능한 여성 파트너는 어떤 분들이 있나요?
            </p>
            <p class="rig-content" style="margin-bottom: 30px">
              모델, 승무원, 대학생, 직장인 등 다양한 여성분과 만남이 가능하며,
              오직 저희 플랫폼을 통해서만 만남이 가능합니다.
            </p>
            <p class="rig-title">서비스 가능 지역</p>
            <p class="rig-content">
              전국 모든 도시에서 만남이 가능하며, 일부 도서지역은 예약을 통하여
              만남이 가능합니다.
            </p>
            <!-- <p class="rig-content">
							본 플랫폼은 파트너를 연결해주는 전국적인 데이트 플랫폼입니다. 각 사용자의 개인 정보 보호를 보장하기 위해 고객은 리셉션에 연락하거나 플랫폼 선배의 실명을 소개해야만
							참여할 수 있습니다.
						</p> -->
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <van-tabbar
      v-model="active"
      active-color="#7e5678"
      :border="true"
      inactive-color="#979799"
    >
      <van-tabbar-item
        v-for="(k, key) in item"
        replace
        :to="k.router"
        :key="key"
      >
        <span>{{ k.title }}</span>
        <template #icon="props">
          <img
            :src="props.active ? k.icon.active : k.icon.noactive"
            :alt="k.title"
            v-show="key !== 2"
          />
          <img
            :src="props.active ? k.icon.active : k.icon.noactive"
            :alt="k.title"
            class="tui"
            style="height: 4rem"
            v-show="key === 2"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 2,
      index: 0,
      item: [
        {
          router: "/Home",
          title: "메인페이지",
          icon: {
            active: "/img/footer/首页选中.jpg",
            noactive: "/img/footer/首页未选中.jpg",
          },
        },
        {
          router: "/Game",
          title: "예약",
          icon: {
            active: "/img/footer/预约选中.jpg",
            noactive: "/img/footer/预约未选中.jpg",
          },
        },
        {
          router: "/Choose",
          title: "님프",
          icon: {
            active: "/img/footer/beauty.52660ad1.png",
            noactive: "/img/footer/beauty.52660ad1.png",
          },
        },
        {
          router: "/Video",
          title: "동영상",
          icon: {
            active: "/img/footer/视频选中.jpg",
            noactive: "/img/footer/视频未选中.jpg",
          },
        },
        {
          router: "/Mine",
          title: "MY페이지",
          icon: {
            active: "/img/footer/我的选中.jpg",
            noactive: "/img/footer/我的未选中.jpg",
          },
        },
      ],
      windowHeight: 0,
      zonghei: 0,
      numHeight: 0,
      Tabactive: 3,
      list: [
        {
          id: 1,
          arrImg: [
            require("../../assets/gif/1-1.webp"),
            require("../../assets/gif/1-2.webp"),
            require("../../assets/gif/1-3.webp"),
            require("../../assets/gif/1-4.webp"),
            require("../../assets/gif/1-5.gif"),
            require("../../assets/gif/1-6.gif"),
          ],
        },
        {
          id: 2,
          arrImg: [
            require("../../assets/gif/2-1.gif"),
            require("../../assets/gif/2-2.webp"),
            require("../../assets/gif/2-3.webp"),
            require("../../assets/gif/2-4.webp"),
            require("../../assets/gif/2-5.webp"),
            require("../../assets/gif/2-6.gif"),
            require("../../assets/gif/2-7.gif"),
            require("../../assets/gif/2-8.gif"),
            require("../../assets/gif/2-9.gif"),
          ],
        },
        {
          id: 3,
          arrImg: [
            require("../../assets/gif/3-1.gif"),
            require("../../assets/gif/3-2.webp"),
            require("../../assets/gif/3-4.webp"),
            require("../../assets/gif/3-5.webp"),
            require("../../assets/gif/3-6.webp"),
            require("../../assets/gif/3-7.webp"),
            require("../../assets/gif/3-8.gif"),
            require("../../assets/gif/3-9.gif"),
          ],
        },
        {
          id: 4,
          arrImg: [
            require("../../assets/gif/4-1.gif"),
            require("../../assets/gif/4-2.gif"),
            require("../../assets/gif/4-3.gif"),
            require("../../assets/gif/4-4.webp"),
            require("../../assets/gif/4-5.webp"),
            require("../../assets/gif/4-6.webp"),
            require("../../assets/gif/4-7.webp"),
            require("../../assets/gif/4-8.webp"),
            require("../../assets/gif/4-9.webp"),
            require("../../assets/gif/4-10.webp"),
            require("../../assets/gif/4-11.webp"),
          ],
        },
        {
          id: 5,
          arrImg: [
            require("../../assets/gif/5-1.gif"),
            require("../../assets/gif/5-2.gif"),
            require("../../assets/gif/5-3.gif"),
            require("../../assets/gif/5-4.webp"),
            require("../../assets/gif/5-5.webp"),
            require("../../assets/gif/5-6.webp"),
            require("../../assets/gif/5-7.webp"),
            require("../../assets/gif/5-8.webp"),
            require("../../assets/gif/5-9.webp"),
            require("../../assets/gif/5-10.webp"),
          ],
        },
        {
          text: "대전",
          id: 6,
          arrImg: [
            require("../../assets/gif/6-1.gif"),
            require("../../assets/gif/6-2.gif"),
            require("../../assets/gif/6-3.webp"),
            require("../../assets/gif/6-4.webp"),
            require("../../assets/gif/6-5.webp"),
            require("../../assets/gif/6-6.gif"),
            require("../../assets/gif/6-7.webp"),
            require("../../assets/gif/6-8.webp"),
            require("../../assets/gif/6-9.webp"),
            require("../../assets/gif/6-10.webp"),
            require("../../assets/gif/6-11.webp"),
            require("../../assets/gif/6-12.gif"),
          ],
        },
        {
          text: "울산",
          id: 7,
          arrImg: [
            require("../../assets/gif/7-1.webp"),
            require("../../assets/gif/7-2.webp"),
            require("../../assets/gif/7-3.webp"),
            require("../../assets/gif/7-4.webp"),
            require("../../assets/gif/7-5.webp"),
            require("../../assets/gif/7-6.webp"),
            require("../../assets/gif/7-7.webp"),
            require("../../assets/gif/7-8.webp"),
            require("../../assets/gif/7-9.webp"),
          ],
        },
        {
          text: "세종특별자치시",
          id: 8,
          arrImg: [
            require("../../assets/gif/8-1.gif"),
            require("../../assets/gif/8-2.gif"),
            require("../../assets/gif/8-3.gif"),
            require("../../assets/gif/8-4.gif"),
            require("../../assets/gif/8-5.webp"),
            require("../../assets/gif/8-6.webp"),
            require("../../assets/gif/8-7.webp"),
            require("../../assets/gif/8-8.webp"),
            require("../../assets/gif/8-9.webp"),
            require("../../assets/gif/8-10.webp"),
          ],
        },
        {
          text: "제주도",
          id: 9,
          arrImg: [
            require("../../assets/gif/9-1.gif"),
            require("../../assets/gif/9-2.gif"),
            require("../../assets/gif/9-3.webp"),
            require("../../assets/gif/9-4.webp"),
            require("../../assets/gif/9-5.webp"),
            require("../../assets/gif/9-6.gif"),
            require("../../assets/gif/9-7.gif"),
            require("../../assets/gif/9-8.gif"),
            require("../../assets/gif/9-9.gif"),
            require("../../assets/gif/9-10.gif"),
            require("../../assets/gif/9-11.gif"),
          ],
        },
        {
          text: "경기도",
          id: 10,
          arrImg: [
            require("../../assets/gif/10-1.gif"),
            require("../../assets/gif/10-2.gif"),
            require("../../assets/gif/10-3.gif"),
            require("../../assets/gif/10-4.gif"),
            require("../../assets/gif/10-5.webp"),
            require("../../assets/gif/10-6.webp"),
            require("../../assets/gif/10-7.webp"),
            require("../../assets/gif/10-8.webp"),
            require("../../assets/gif/10-9.webp"),
            require("../../assets/gif/10-10.webp"),
          ],
        },
        {
          text: "강원도",
          id: 11,
          arrImg: [
            require("../../assets/gif/11-1.gif"),
            require("../../assets/gif/11-2.gif"),
            require("../../assets/gif/11-3.gif"),
            require("../../assets/gif/11-4.gif"),
            require("../../assets/gif/11-5.webp"),
            require("../../assets/gif/11-6.webp"),
            require("../../assets/gif/11-7.webp"),
            require("../../assets/gif/11-8.webp"),
            require("../../assets/gif/11-9.webp"),
          ],
        },
        {
          text: "충청북도",
          id: 12,
          arrImg: [
            require("../../assets/gif/12-1.gif"),
            require("../../assets/gif/12-2.gif"),
            require("../../assets/gif/12-3.webp"),
            require("../../assets/gif/12-4.webp"),
            require("../../assets/gif/12-5.webp"),
            require("../../assets/gif/12-6.gif"),
            require("../../assets/gif/12-7.gif"),
            require("../../assets/gif/12-8.gif"),
            require("../../assets/gif/12-9.gif"),
            require("../../assets/gif/12-10.gif"),
            require("../../assets/gif/12-11.gif"),
          ],
        },
        {
          text: "충청남도",
          id: 13,
          arrImg: [
            require("../../assets/gif/13-1.gif"),
            require("../../assets/gif/13-2.gif"),
            require("../../assets/gif/13-3.webp"),
            require("../../assets/gif/13-4.webp"),
            require("../../assets/gif/13-5.webp"),
            require("../../assets/gif/13-6.webp"),
            require("../../assets/gif/13-7.webp"),
            require("../../assets/gif/13-8.webp"),
            require("../../assets/gif/13-9.webp"),
          ],
        },
        {
          text: "전라북도",
          id: 14,
          arrImg: [
            require("../../assets/gif/14-1.gif"),
            require("../../assets/gif/14-2.gif"),
            require("../../assets/gif/14-3.gif"),
            require("../../assets/gif/14-4.gif"),
            require("../../assets/gif/14-5.gif"),
            require("../../assets/gif/14-6.webp"),
            require("../../assets/gif/14-7.webp"),
            require("../../assets/gif/14-8.webp"),
            require("../../assets/gif/14-9.webp"),
            require("../../assets/gif/14-10.webp"),
          ],
        },
        {
          text: "전라남도",
          id: 15,
          arrImg: [
            require("../../assets/gif/15-1.gif"),
            require("../../assets/gif/15-2.gif"),
            require("../../assets/gif/15-3.gif"),
            require("../../assets/gif/15-4.gif"),
            require("../../assets/gif/15-5.webp"),
            require("../../assets/gif/15-6.webp"),
            require("../../assets/gif/15-7.webp"),
            require("../../assets/gif/15-8.webp"),
          ],
        },
        {
          text: "경상북도",
          id: 16,
          arrImg: [
            require("../../assets/gif/16-1.gif"),
            require("../../assets/gif/16-2.webp"),
            require("../../assets/gif/16-3.gif"),
            require("../../assets/gif/16-4.gif"),
            require("../../assets/gif/16-5.webp"),
            require("../../assets/gif/16-6.webp"),
            require("../../assets/gif/16-7.webp"),
          ],
        },
        {
          text: "경상남도",
          id: 17,
          arrImg: [
            require("../../assets/gif/17-1.gif"),
            require("../../assets/gif/17-2.gif"),
            require("../../assets/gif/17-3.gif"),
            require("../../assets/gif/17-4.gif"),
            require("../../assets/gif/17-7.gif"),
            require("../../assets/gif/17-8.gif"),
            require("../../assets/gif/17-9.gif"),
            require("../../assets/gif/17-10.gif"),
          ],
        },
      ],
      addlist: [
        {
          0: "베이징",
          1: "상하이",
          2: "광저우",
          3: "심천",
        },
        {
          0: "난징",
          1: "쯔엉사",
          2: "충칭",
          3: "시안",
        },
      ],
    };
  },

  methods: {
    back() {
      // this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
      this.$router.push({
        path: "Choose",
      });
    },
    changeBtn(e) {
      this.Tabactive = e;
      console.log(this.Tabactive, "eee");
      if (this.Tabactive == 0) {
        this.back();
      }
    },

    updateWindowHeight() {
      this.windowHeight = window.innerHeight;

      setTimeout(() => {
        let Height = this.$refs.header.$el.offsetHeight;
        let tabHeights = this.$refs.topTabs.$el.offsetHeight;
        // const bottomTabs = this.$refs.bottomTabs.$el.offsetHeight;
        this.zonghei = Height + tabHeights + 70;
        this.numHeight = this.windowHeight - this.zonghei;
      }, 500);
    },
  },
  mounted() {
    this.updateWindowHeight();
    window.addEventListener("resize", this.updateWindowHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  created() {
    this.index = this.$route.query.id;
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-tab__text {
  color: #c24491;
}

::v-deep .van-tabs__line {
  display: none;
  background-color: #c24491 !important;
}

.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.nav-bar {
  // background: linear-gradient(90deg, #775fd9, #c24491);
  background: #e879f9;
  height: 100px;
}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.container {
  overflow: auto;
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 1.4rem;
  background: #f2f2f5;
  background: url(../../assets/login-bg.png);
  background-size: cover;
  background-position: 50%;
}

.addname {
  // text-align: center;
  // transform: translateY(30px);

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #fff;
  // font-weight: 700;
}

::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}

::v-deep .van-tabs__line {
  background-color: #7e5678;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

.card {
  // background-color: #775fd9;
  // padding: 0.625rem;
  // width: 95%;
  // color: white;
  // font-size: 1rem;
  // margin: 0.625rem auto;
  // line-height: 1.5rem;
  // border-radius: 0.375rem;

  // font-weight: 700;
  width: calc(100% - 40px);
  height: auto;
  margin: 40px auto;
}

.card_txt {
  color: #fff;
  padding: 10px 20px;
  height: auto;
  font-size: 30px;
  padding: 20px 40px;
  margin: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(232, 121, 249, 0.5);
}

::v-deep .van-row--flex {
  // height: 80px;
  width: 100%;
  line-height: 80px;
}

::v-deep .van-col {
  // height: 80px;
  width: 100%;
}

.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}

.rig-title {
  color: #c24491;
  font-size: 32px;
  font-weight: 700;
}

.rig-content {
  font-size: 32px;
  color: #000;
  // margin-top: 10px;
}

::v-deep .van-col {
  color: #c24491;
}

.address {
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.address_li {
  width: 100%;

  img {
    width: 100%;
    object-fit: contain;
  }
}

@tabbar-height: 110px;
@tabbar-img: 75px;

.van-tabbar {
  height: @tabbar-height;
}

.van-tabbar-item__icon img {
  height: @tabbar-img;
}

.van-tabbar-item {
  font-size: 26px;
}

.tui {
  width: 4rem;
  margin-top: -10.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}

[class*="van-hairline"]::after {
  border: none !important;
}
</style>
